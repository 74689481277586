/********************** Widths **********************/
@media screen and (max-width: 300px) {
    .mobile-title-height {
        display: contents;
    }

    .margin-bottom-300 {
        margin-bottom: 5px !important;
    }
}

@media screen and (max-width: 365px) {
    .width-145-max-300 {
        max-width: 145px;
    }

    .footer-mobile {
        height: 215px !important;
    }
}

@media screen and (max-width: 397px) {
    .margin-top-20-397 {
        margin-top: 20px !important;
    }

    .width-80-max-397 {
        max-width: 80% !important;
    }
}

@media screen and (max-width: 500px) {
    .celebrations-title-mobile {
        width: 150px;
    }

    .celebrations-product-text {
        width: 50% !important;
    }

    header .btn-try button {
        padding: 5px 25px;
        font-size: 15px;
    }

    .smaller-mobile-profile-img {
        width: 60px !important;
        height: 60px !important;
    }

    .smaller-mobile-profile-img-40 {
        width: 40px !important;
        height: 40px !important;
    }

    .center-bio  {
        margin-right: auto;
        margin-left: auto;
        display: block;
        width: 100%;
    }
}

@media screen and (min-width: 550px) and (max-width: 1024px) {
    .center-bio  {
        margin-right: auto;
        margin-left: auto;
        display: block;
        width: 70%;
    }
}

@media screen and (min-width: 576px) {
    .modal-sm {
        --bs-modal-width: 320px;
    }
}

@media screen and (max-width: 600px) {
    .mobile-card {
        width: 90% !important;
    }

    .iframe-size-responsive {
        width: 90% !important;
        height: 67% !important;
    }
}

@media screen and (min-width: 601px) and (max-width: 825px) {
    .iframe-size-responsive {
        width: 500px !important;
        height: 375px !important;
    }
}

@media screen and (max-width: 767px) {
    .nav-icon {
        color: rgba(30, 28, 36, 0.85) !important;
        font-size: 20px !important;
    }

    .gift-notification {
        left: 88% !important;
        margin-left: 20px !important;
        margin-top: 6px;
    }

    .request-notification {
        left: 88% !important;
        margin-left: 20px !important;
        margin-top: 5px;
    }

    .bottom-navigation {
        display: block !important;
        z-index: 2;
    }
}

@media screen and (max-width: 767px) {
    .footer-mobile {
        height: 195px;
    }

    .padding-24-under-767 {
        padding: 8px 24px !important;
    }

    .min-height-mobile {
        height: 100% !important;
        max-height: 500px !important;
    }

    .mobile-only {
        display: block !important;
    }

    .msg-notification-under-767 {
        right: 0px !important;
    }

    .bottom-input-under-767 {
        position: fixed !important;
        bottom: 0px !important;
        padding: 10px 5px 16px 10px !important;
    }
}

@media screen and (min-width: 767px) {
    .footer-mobile {
        height: 150px;
    }
}

@media screen and (min-width: 768px) {
    .hide-notif-on-large-screen {
        display: none !important;
    }
}

@media screen and (max-width: 825px) {
    .container {
        max-width: 100%;
        overflow-x: overlay;
    }

    .photo-modal-content {
        max-width: 90%;
        max-height: 90%;
    }

    .offers-figure {
        width: 80%;
        margin: auto;
        max-width: 250px;
    }

    .mobile-button-center {
        display: block !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .mobile-width-100 {
        width: 100% !important;
    }

    .mobile-center {
        margin: auto;
    }

    .mobile-hr {
        width: 95% !important;
    }

    .connections-buttons {
        position: inherit !important;
        right: inherit !important;
        display: grid;
        justify-content: center;
    }

    .search-navbar {
        max-width: 315px !important;
    }

    .search-input {
        width: 90% !important;
    }

    .search-navbar-suggestions {
        max-width: 283px !important;
        width: 80% !important;
    }

    .navmodal-buttons {
        text-decoration: none;
        display: block;
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: 10px !important;
        margin-bottom: 20px !important;
        text-align: center !important;
        font-size: 16px !important;
        color: rgb(0, 0, 0) !important;
    }

    .navbar-toggler:focus {
        box-shadow: none;
    }

    .margin-bottom-825 {
        margin-bottom: 10px !important;
        text-align: center;
    }
}

@media screen and (min-width: 826px) {
    .iframe-size-responsive {
        width: 700px !important;
        height: 525px !important;
    }
}

@media screen and (max-width: 900px) {
    .profile-img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        float: none !important;
    }

    .profile-header-item {
        width: 100%;
        margin-bottom: 3px;
        margin-left: auto;
        margin-right: auto;
    }

    .header-padding {
        padding: 0rem 0.5rem;
    }

    .single-center-button {
        margin-top: 5px !important;
        margin-left: auto !important;
    }

    .margin-y-900 {
        margin: 5px 0px 0px 0px !important;
    }

    .loading-div {
        margin: 0px !important;
        margin-bottom: 10px !important;
    }

    .loading-div-2 {
        margin-top: 15px !important;
    }

    .profile-details {
        display: grid;
        justify-content: center;
        margin-left: 0px !important;
        text-align: center;
    }
}

@media screen and (min-width: 901px) {
    .spinner-large-screen {
        left: 45% !important;
    }
}

@media screen and (max-width: 1100px) {
    .dropdown {
        margin-top: 10px;
    }

    .center-header {
        justify-content: center !important;
        margin-top: 10px !important;
    }

    .handle-center {
        text-align: center !important;
        margin-bottom: 25px;
    }

    .banner-100 {
        width: 100% !important;
    }

    .margin-ipad-20 {
        margin-bottom: 20px !important;
    }
}

@media screen and (max-width: 1199px) {
    .margin-bottom-1199 {
        margin-bottom: 50px !important;
    }
}

@media screen and (max-width: 1580px) {
    .center-text-1580-px {
        margin-right: 0px !important;
        margin-top: 10px;
        text-align: center;
    }
}

/*********************** Heights ***********************/
@media screen and (max-height: 570px) {
    .msgs-height-various {
        height: 64vh !important;
    }
}

@media screen and (min-height: 571px) and (max-height: 609px) {
    .msgs-height-various {
        height: 65vh !important;
    }
}

@media screen and (min-height: 610px) and (max-height: 692px) {
    .msgs-height-various {
        height: 67vh !important;
    }
}

@media screen and (min-height: 693px) and (max-height: 750px) {
    .msgs-height-various {
        height: 71vh !important;
    }
}

@media screen and (min-height: 751px) and (max-height: 787px) {
    .msgs-height-various {
        height: 72vh !important;
    }
}

@media screen and (min-height: 788px) {
    .msgs-height-various {
        height: 77vh !important;
    }
}
