@import url('https://fonts.googleapis.com/css?family=Montserrat');

* {
	box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #fff;
  height: 100%;
  min-height: 100%;
}

.center {
  display: block !important;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.center-width-24rem {
  max-width: 24rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.center-width-600 {
  max-width: 600px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

/* Modal CSS
****************************************************************************/

/* Style the Image Used to Trigger the Modal */
#myImg {
  cursor: pointer;
  transition: 0.3s;
}

#myImg:hover {opacity: 0.7;}

/* The Modal (background) */
.photo-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (Image) */
.photo-modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 500px;
}

/* Add Animation - Zoom in the Modal */
.photo-modal-content, #caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {transform:scale(0)}
  to {transform:scale(1)}
}

/* The Close Button */
.close {
  position: absolute;
  top: 55px;
  right: 12px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .photo-modal-content {
    width: 100%;
  }
}

.modal {
  background-color: rgba(244, 244, 244, 0.8);
  box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}

.modal-content {
  border: 0px;
}

#share-modal-header > .btn-close {
  margin: 0px;
  left: 10px;
  bottom: 27px;
  position: relative;
}

/* Tab Gallary
****************************************************************************/

/* The grid: Four equal columns that floats next to each other */
.column {
  float: left;
  padding: 10px;
}

/* Style the images inside the grid */
.column img {
  opacity: 0.8;
  cursor: pointer;
}

.column img:hover {
  opacity: 1;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.profileThumbnailContainer {
  position: relative;
}

.profileThumbnailContainer .overlay {
  position: absolute;
  top: 20%;
  left: 80%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #555;
  color: white;
  padding: 5px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
}

/* Profile CSS below 
****************************************************************************/
input[type="file"] {
  display: none;
}

.MuiFormControlLabel-label {
  font-family: inherit !important;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 0 !important;
  letter-spacing: normal !important;
}

.css-1c35hjw-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0px !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  min-height: 59px !important;
  height: 60px !important;
  border: 0 !important;
  padding: 0px 16px 0px 16px !important;
  color: rgba(0, 0, 0, 0.54) !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  border-bottom: 1px solid #E2E8F0 !important;
  height: 60px !important;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 0px 0px 20px 0px !important;
}

.css-o4b71y-MuiAccordionSummary-content {
  display: inline-block !important;
  text-align: right;
}

.css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
  margin: 0px;
}

.css-ygrcj8-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23) !important;
  border-width: 1px !important;
}

.css-imwso6-MuiBottomNavigationAction-label.Mui-selected {
  font-size: 11px !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  padding: 0 122px 0 8px !important;
}

/* Navbar
****************************************************************************/

header {
  width: 1290px;
  margin: auto;
  max-width: 95%;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
}

header .btn-try{
  display: flex;
  flex-direction: column;
}

header .btn-try button {
  font-family: inherit;
  padding: 10px 30px;
  background-color: transparent;
  outline: none;
  border: 1px solid #525FFB;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.05em;
  line-height: 27px;
  text-align: left;
  color: #525ffb;
  border-radius: 40px;
  cursor: pointer;
}

.search-navbar {
  width: 373px;
  position: relative;
}

.search-navbar .fas {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 18px;
  color: #9fa3b1;
  cursor: pointer;
}

.profile-dropdown {
  display: none;
}

.profile-dropdown.active {
  display: block;
}

.gift-notification {
  opacity: 0;
  margin-top: 1px;
}

.gift-notification.active {
  opacity: 1;
}

.request-notification {
  opacity: 0;
  margin-top: 1px;
}

.request-notification.active {
  opacity: 1;
}

.greeters-notification {
  opacity: 0;
}

.greeters-notification.active {
  opacity: 1;
}

/* Search bar
****************************************************************************/
.search-bar {
  width: 100%;
  max-width: 333px;
  position: relative;
}

.search-bar .fas {
  display: none;
  position: absolute;
  top: 17px;
  right: 13px;
  font-size: 16px;
  color: #9fa3b1;
  cursor: pointer;
}

.fas.active {
  display: block;
}

/* Success Message
****************************************************************************/

.MuiAlert-message {
  width: 100%
}

/* Offer
****************************************************************************/

.MuiCardHeader-avatar {
  margin-right: 10px !important;
}

.input-textarea {
  border: 1px solid rgba(206,206,206,1.00);
  outline: none;
  border-radius: 13px;
  padding: 10px;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.04em;
  text-align: left;
  color: rgba(30, 28, 36, 0.85);
  width: 100%;
  max-width: 333px;
  margin-bottom: 10px;
}

/* Product
****************************************************************************/

.zoom {
  transition: transform .2s;
}

.zoom:hover {
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  transform: scale(1.15);
}

/* Dashboard
****************************************************************************/

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid gray;
  text-align: left;
  padding: 8px;
  max-width: 50px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
